@import '../../../styles/variables';

.Options {
    position: absolute;
    bottom: 0;
    right: 0;

    padding: 0 20px 20px 0;
    z-index: 2;

    &__Warning {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 14px;
        color: $white;
        font-size: 14px;
        left: -107px;
        height: 22px;
        line-height: 22px;
        position: absolute;
        padding-left: 10px;
        width: 130px;
        top: 6px;

        opacity: 0;
        transition: opacity 0.5s ease;

        &--active {
            opacity: 1;

            + .Options__Icon {
                transform: rotate(45deg);
            }
        }
    }

    &__Icon {
        appearance: none;
        background: rgba(0, 0, 0, 0) url('../assets/cog.svg') no-repeat left top;
        background-size: contain;
        border: none;
        color: $white;
        fill: $white;
        cursor: pointer;
        position: relative;
        height: 34px;
        width: 34px;
        z-index: 999;
        filter: drop-shadow($boxShadow);
        transition: transform 0.5s ease;
    }
}

.videoFullscreen {
    .Options {
        display: none;
    }
}

@media only screen and (max-width: 469px) {
    .Options {
        padding: 0 10px 10px 0;
    }
}
