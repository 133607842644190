@import '../../../styles/variables';

.Menu {
    background: transparentize($color: $properBlack, $amount: 0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    position: relative;
    transition: opacity 0.5s ease;
    z-index: 3;
    font-size: 20px;

    color: $white;

    &--active {
        pointer-events: all;
        opacity: 1;
    }

    &__Title {
        text-align: center;
    }

    &__Container {
        width: 30%;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        background-color: $properBlack;
        border-radius: 10px;
    }

    &__Row {
        display: flex;
        justify-content: space-around;
        margin-bottom: 1em;
    }

    &__Close {
        border: none;
        background-color: transparent;
        color: white;
        padding: 10px 0;
        cursor: pointer;
        border-radius: 5px;
        margin-left: auto;
        font-size: 12pt;
    }

    &__Button {
        border-radius: 10px;
        font-size: 1em;
        background-color: white;
        cursor: pointer;
        padding: 8px 16px;
        margin-top: 10px;
        border: 0;
        &.active {
            background-color: $blue;
            color: white;
        }
    }

    &__Radio {
        display: flex;
        justify-content: center;
        input {
            margin-right: 5px;
        }
    }

    h1 {
        font-size: 2em;
        margin-bottom: 0.5em;
    }

    h2 {
        font-size: 1.5em;
        margin-bottom: 0.25em;
    }
}

@media only screen and (max-width: 768px) {
    .Menu {
        &__Container {
            width: 90%;
        }
    }
}
