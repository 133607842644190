@import '../../../styles/variables';

.Days {
    padding: 20px 20px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    & .animate {
        transition: transform 0.5s ease;
        transform-origin: top right;
    }

    &__Icon {
        background: url('../assets/note.svg') no-repeat left top;
        background-size: cover;
        padding: 15px 0;
        height: 68px;
        width: 70px;
        transition: transform 0.5s ease;
        transform-origin: top right;
        animation: growAndShrink 3s forwards;
    }

    &__Text {
        display: block;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;

        &--large {
            font-size: 38px;
            line-height: 0.8;
        }
    }
}

.videoFullscreen {
    .Days {
        visibility: hidden;
    }
}

@media only screen and (max-width: 469px) {
    .Days {
        padding: 10px 10px 0 0;
        right: 70px;
    }
}

@keyframes growAndShrink {
    0% {
        transform: scale(1);
    }
    35% {
        transform: scale(2);
    }
    70% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}
