@import '../../../styles/variables';

.Percent {
    position: absolute;
    top: 90px;
    right: 24px;
    z-index: 2;
    width: 62px;
    height: 57px;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    svg {
        width: 100%;
        height: 100%;
    }
    &__Text {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: $weightBlack;
        font-size: 13px;
    }
}

.videoFullscreen {
    .Percent {
        visibility: hidden;
    }
}

@media only screen and (max-width: 469px) {
    .Percent {
        top: 16px;
        right: 10px;
    }
}
