* {
    box-sizing: border-box;
}
@import "reset";
@import "variables";
@import "helpers";
@import "fonts";
@import "videojs";

body,
.smooth-container {
    scroll-behavior: smooth;
}

body {
    background: $properBlack;
}

// Example scrollbar styling
//  See https://css-tricks.com/custom-scrollbars-in-webkit/
::-webkit-scrollbar {
    display: none;
}

button:focus,
button:active {
    outline: none;
}

img,
video {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#consent {
    margin: 0;
    border-radius: 0;
    width: 100%;
}

#start-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    #main-start-screen {
        background-position: center;
        background-image: url("/img/cap/bitbBG.png");
        background-repeat: no-repeat;
        display: block;
        width: 100%;
        flex: 1;
        background-size: cover;
        max-height: 70vh;
        position: relative;
        padding: 0;
    }

    #menu-bar {
        height: 50px;
        width: 100%;
        background-color: #000000;
        position: absolute;
        z-index: 10;
        display: flex;
        justify-content: flex-end;
    }

    #menu-bar-content {
        flex-direction: column;
        display: block;
        max-width: 1024px;
        align-items: center;
    }

    #menu-links {
        padding-top: 15px;
        padding-bottom: 15px;
        opacity: unset;

        a {
            font-family: "CircularStd";
            font-size: 14px;
            color: #ffffff;
            display: inline;
            padding-right: 15px;
            text-transform: uppercase;
            text-decoration: none;
        }
    }

    #main-start-screen-content {
        display: block;
        max-width: 1024px;
        position: relative;
        margin: 0 auto;
        display: none;
        height: 100%;
    }

    #nathan-pic {
        margin-bottom: -2px;
        padding-right: 10%;
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        order: 1;
        img {
            width: 100%;
            max-height: 100%;
            max-height: stretch;
        }
    }

    .leftHandContent {
        padding-top: 50px;
        margin-left: 85px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        order: 0;
    }

    .contentWrapper {
        position: relative;
        padding: 10px 0;
    }

    #bitb-logo {
        padding-bottom: 20px;
        img {
            max-width: 100%;
        }
    }

    #subheading {
        padding-bottom: 20px;
        h1 {
            font-family: "BenguiatITC";
            font-size: 27px;
            line-height: 33px;
            text-transform: uppercase;
            color: #ffffff;
        }
    }

    #video {
        width: 480px;
        height: 300px;
    }

    #banner-video {
        width: 100%;
        height: 100%;
        video {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }

    .control {
        position: absolute;
        right: 60px;
        bottom: 30px;
        width: 50px;
        height: 50px;
        background: url("/img/cap/pause.svg") center center no-repeat;
        cursor: pointer;
        display: none;
    }

    .volume {
        position: absolute;
        right: 30px;
        bottom: 39px;
        width: 30px;
        height: 30px;
        background: url("/img/cap/volume-mute.svg") center center no-repeat;
        cursor: pointer;
        color: white;
        display: none;
    }

    .muted {
        .volume {
            background: url("/img/cap/volume.svg") center center no-repeat;
        }
    }

    .ready {
        .control,
        .volume {
            display: block;
        }
    }

    .paused {
        .control {
            background: url("/img/cap/play.svg") center center no-repeat;
        }
    }

    .legal {
        color: white;
        text-align: center;
        font-size: 12px;
        padding-bottom: 15px;
    }
    .game-buttons {
        display: none;
        button {
            text-align: center;
            background-color: red;
            border: 0px;
            color: #ffffff;
            padding: 10px 20px;
            font-family: "BenguiatITC";
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14pt;
            border-radius: 3px;
            position: relative;
            cursor: pointer;
            border: 2px solid transparent;
            &:last-child {
                margin-left: 5px;
            }
        }
        &.desktop {
            margin-top: 20px;
        }
    }
    button:hover {
        background-color: #730210;
    }

    button:focus {
        border: 2px solid #fff200;
    }

    h1 {
        color: white;
        font-size: 34px;
        font-family: "BenguiatITC";
        text-transform: uppercase;
        text-align: center;
        padding: 0 20px;
        margin-top: 20px;
    }

    #footer {
        width: 100%;
        #footer-content {
            padding: 45px 20px 20px 20px;
            margin: 0 auto;
            max-width: 1024px;
            display: flex;
            position: relative;
            z-index: 10;
            justify-content: space-between;
        }
        #logos {
            display: flex;
            align-items: center;
        }

        #theSpaceLogo {
            padding-left: 15px;
        }

        #joipolloiLogo {
            padding-left: 15px;
            margin-right: 20px;
        }

        #social-media {
            display: flex;
            align-items: center;
            .social-icons {
                height: 30px;
                width: 30px;
                margin-right: 25px;
                padding-left: 20px;
            }
            .social-icons:hover {
                transform: scale(1.2);
                transition: transform 0.2s ease-in-out;
            }
            .social-icons:active {
                opacity: 70%;
            }
            .social-icons:focus {
                outline: 1px solid #fff200;
            }
        }
        #mobile-icons {
            display: none;
        }
    }

    .footer-video {
        height: 0;
        width: 0;
        visibility: hidden;
        text-align: center;
        video {
            width: 100% !important;
            height: auto !important;
        }
    }

    #footer-menu {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        ul {
            display: flex;
            margin: 0 auto;
            li {
                margin: 0 10px;
                a {
                    color: white;
                    text-decoration: none;
                    font-size: 14px;
                }
            }
        }
    }

    #kofi {
        text-align: center;
        .kofi-button {
            margin: 0 auto;
        }
    }
}

#end-screen {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-animation: endFade 1s forwards;
    -moz-animation: endFade 1s forwards;
    -o-animation: endFade 1s forwards;
    -ms-animation: endFade 1s forwards;
    animation: endFade 1s forwards;
    text-align: center;
    img {
        width: 1024px;
        height: 768px;
    }
}

.confirm-choice {
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    left: 25px;
    right: 0;
    z-index: 100;
    height: 100px;
    text-align: center;
    padding: 10px;
    color: #ffffff;
    margin-top: -10px;
    border-radius: 10px;
    p {
        padding: 10px;
        font-size: 15px;
        font-weight: 800;
    }

    button {
        cursor: pointer;
        border: none;
        padding: 10px;
        margin: 5px;
        background: #fff;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 800;
    }
}

.hidden {
    display: none;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 1200px;
    p {
        margin-bottom: 1em;
        line-height: 1.4;
    }
    img,
    video {
        width: 100%;
        display: block;
        margin-bottom: 1em;
    }
    img {
        max-width: 300px;
        margin: 0 auto 1em;
    }
    a {
        color: $properBlack;
    }
    @media only screen and (max-width: 767px) {
        width: 90%;
    }
}

.modal-header {
    h2 {
        font-family: "BenguiatITC";
        font-size: 27px;
        line-height: 33px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
}

#webby-pv-2014-roadblock {
    display: none;

    @media only screen and (min-width: 1051px) {
        display: block;
    }
}

.webby {
    display: block;
    max-width: 180px;
    margin-top: 20px;
    img {
        width: 100%;
    }
}

@keyframes endFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 1024px) {
    #start-screen {
        #nathan-pic {
            padding-right: 0;
            padding-left: 10px;
        }

        .leftHandContent {
            margin-left: 0px;
        }
    }
}

@media only screen and (max-width: 820px) {
    #start-screen {
        h1 {
            font-size: 21px;
        }

        #main-start-screen {
            max-height: none;
        }
        #footer #footer-content {
            display: flex;
            flex-direction: column;
            padding: 15px;
        }

        #footer #logos {
            display: none;
        }
        #footer #social-media {
            display: none;
        }
        #footer #social-media-mobile {
            display: flex;
        }
        #footer #logos-flex {
            display: flex;
        }
        .social-icons {
            display: flex;
            align-items: center;
            margin-right: 0;
        }

        #footer #mobile-icons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        #footer #mobile-icons #logos-mobile {
            display: flex;
            align-items: center;
        }
        #footer {
            #theSpaceLogo {
                padding-left: 5px;
            }
            #joipolloiLogo {
                padding-left: 5px;
                margin-right: 0;
                display: flex;
            }
        }

        #footer #facebook {
            padding-left: 10px;
        }

        #footer #instagram {
            padding-left: 10px;
        }
    }
}

@media only screen and (max-width: 767px) {
    #start-screen {
        #banner-video,
        .control,
        .volume,
        .desktop {
            display: none !important;
        }
        .footer-video {
            width: auto;
            height: auto;
            visibility: visible;
        }
        #main-start-screen {
            position: static;
            padding: 0 15px;
        }
        #main-start-screen-content {
            display: flex;
        }
    }
}

@media only screen and (max-width: 700px) {
    #start-screen {
        #nathan-pic {
            padding-right: 0;
            padding-left: 10px;
            max-width: 70%;
        }

        .leftHandContent {
            margin-left: 0px;
            flex: 1 0 60%;
            max-width: 60%;
        }

        .game-buttons {
            flex-direction: column;
        }

        .game-buttons button {
            padding: 7px 10px;
            margin-bottom: 5px;
            &:last-child {
                margin: 0;
            }
        }

        video,
        #video {
            height: 200px;
            width: 335px;
            margin: 0 auto;
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 469px) {
    .confirm-choice {
        left: 15px;
        right: 15px;
    }

    #start-screen #bitb-logo {
        padding-bottom: 10px;
        img {
            height: 92px;
        }
    }
}

@media only screen and (max-width: 374px) {
    #start-screen {
        #menu-bar {
            justify-content: space-around;
        }
        #menu-links {
            a {
                padding-right: 5px;
            }
            a:last-child {
                padding-right: 0;
            }
        }
        #main-start-screen {
            padding: 0 15px;
        }

        #footer #mobile-icons {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            #logos-mobile {
                margin-bottom: 10px;
            }
        }
    }

    #start-screen #bitb-logo {
        padding-bottom: 10px;
        img {
            height: 65px;
        }
    }

    #start-screen #subheading h1 {
        font-size: 14px;
        line-height: 16px;
    }
}
