@import '../../../styles/variables';

#root,
body,
html,
.debugger {
    height: 100%;
    width: 100%;
}

.Player {
    background: $properBlack;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.Player__Contraints {
    margin: auto;
    position: relative;
    height: 100%;
}

.ChatBot {
    position: fixed;
    left: 590px;
    bottom: 4vh;
    top: 4vh;
    transform: translateX(-100%);
    height: 92vh;
    width: 470px;
    z-index: 1;
    transition: all 0.5s ease;

    &--right {
        left: calc(100% - 100px);
    }

    &--center {
        left: 50%;
        transform: translateX(-50%);
    }

    &__Spacer {
        height: auto;
    }

    &__Scroll {
        max-height: 100%;
        overflow-y: scroll;
        padding: 10px 10px 0px 25px;
        position: absolute;
        z-index: 1;
        bottom: 0;
        min-width: 100%;
        transform: translateZ(0);
        scrollbar-color: transparent transparent;
        -ms-overflow-style: none; /* IE 11 */
        scrollbar-width: none; /* Firefox 64 */
    }

    #spinner {
        position: absolute;
        bottom: -10px;
        left: 40px;
    }
}

.videoFullscreen {
    .ChatBot {
        &__Scroll {
            overflow: visible;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 1023px) {
    .ChatBot {
        left: 50%;
        transform: translateX(-50%);
        &__Scroll {
            padding: 10px 25px 0px 25px;
        }
    }
}

@media only screen and (max-width: 469px) {
    .ChatBot {
        width: 100%;
        left: 0;
        transform: none;
        top: 5px;
        margin-bottom: 20px;
        &__Scroll {
            padding: 10px 15px 0px 15px;
            bottom: 5px;
        }
    }
}
