$white: #fff;
$black: #333;
$properBlack: #000;
$blue: #3a88fd;
$charcoal: #1a364d;
$red: #D0021B;

$boxShadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

/* CircularStd @font-face kit */
$weightBlack: 800;
$weightBold: 600;
$weightMedium: 500;
$weightBook: 400;
