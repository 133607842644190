.video-js {
    .vjs-custom-fullscreen-control {
        display: none;
        cursor: pointer;
        flex: none;
        .vjs-icon-placeholder {
            font-family: VideoJS;
            font-weight: normal;
            font-style: normal;
            &:before {
                content: '\F108';
            }
        }
    }
}

.videoFullscreen {
    .video-js {
        .vjs-custom-fullscreen-control {
            .vjs-icon-placeholder {
                &:before {
                    content: '\F109';
                }
            }
        }
    }
}

.fullscreen {
    .video-js {
        .vjs-fullscreen-control {
            display: none;
        }
        .vjs-custom-fullscreen-control {
            display: block;
        }
    }
}
