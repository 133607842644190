@import '../../../styles/variables';

.Curtain {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $properBlack;
    opacity: 0;
    height: 100%;
    transition: all 1s ease;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;

    &--active {
        opacity: 1;
        z-index: 100000;
    }

    &__Container {
        width: 50%;
        position: relative;

        .lds-ellipsis {
            bottom: 0;
            right: 0;
            left: -60px;
            margin: 0 auto;
        }
        .Card--button {
            background-color: white;
            animation: curtainFadeIn 1s forwards;
            border: 0;
        }
    }
    .Summary {
        &__Scroll {
            margin-bottom: 15px;
            max-height: 50vh;
            height: 100%;

            overflow-y: auto;
            @media only screen and (max-height: 812px) {
                max-height: 40vh;
            }

            @media only screen and (max-height: 667px) {
                max-height: 25vh;
            }

            @media only screen and (max-height: 568px) {
                max-height: 20vh;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .Curtain {
        &__Container {
            width: 90%;
        }
    }
}

@keyframes curtainFadeIn {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
