.Background {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 1s;
    &__Layer {
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center;
            @media (orientation: portrait) {
                object-position: left;
            }
        }
        &.animated {
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;

            &.random-drift {
                animation: random-drift 60s linear infinite;
            }

            &.lr-drift {
                animation: left-right-drift 60s linear infinite;
            }
        }
    }

    &.__new {
        opacity: 1;
    }
}

@media only screen and (max-width: 1023px) {
    .Background {
        &__Layer:not(.first) {
            display: none;
        }
    }
}

@keyframes left-right-drift {
    25% {
        transform: translate3d(40px, 0, 0);
    }

    0%,
    50%,
    100% {
        transform: translate3d(0, 0, 0);
    }

    75% {
        transform: translate3d(-40px, 0, 0);
    }
}

@keyframes random-drift {
    10%,
    90% {
        transform: translate3d(-15px, 12px, 0);
    }

    20%,
    80% {
        transform: translate3d(13px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(0, -14px, 0);
    }

    40%,
    60% {
        transform: translate3d(-12px, 10px, 0);
    }

    50% {
        transform: translate3d(16px, -13px, 0);
    }
}
