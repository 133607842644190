@import '../../../styles/variables';

.Lightbox {
    background: transparentize($color: $properBlack, $amount: 0.2);
    position: absolute;
    justify-content: center;
    height: 100%;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
    z-index: 3;
    padding: 50px 25px;
    top: 0;
    display: flex;
    align-items: center;

    &--active {
        pointer-events: all;
        opacity: 1;
    }

    &__Container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
    }

    &__Image {
        text-align: center;
        position: relative;

        img {
            border-radius: 10px;
            max-width: 100%;
            height: auto;
        }

        &__nav {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin-top: -56px;
            bottom: 0;
            height: 112px;
            pointer-events: none;
            &__button {
                background-color: transparent;
                border: 0;
                padding: 10px;
                font-size: 50pt;
                color: #ffffff;
                transition: 0.5s ease;
                cursor: pointer;
                position: absolute;
                pointer-events: visible;
                bottom: 50%;
                &:hover {
                    color: #cccccc;
                }

                &--left {
                    left: -80px;
                    &:hover {
                        margin-left: -10px;
                    }
                }

                &--right {
                    right: -80px;
                    &:hover {
                        margin-right: -10px;
                    }
                }
            }
            &__counter {
                font-size: 25pt;
                color: #ffffff;
                padding: 10px;
            }
        }
    }

    &__Content {
        border-radius: 10px;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        img {
            border-radius: 10px;
        }

        &__image {
            max-width: 40%;
            margin: auto;
        }
    }

    &__Close {
        border: none;
        background-color: transparent;
        color: white;
        padding: 10px 0;
        cursor: pointer;
        border-radius: 5px;
        margin-left: auto;
        font-size: 12pt;
        display: block;
    }
}

.videoFullscreen {
    .Lightbox {
        &__Content {
            .video-js {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 99999;
                left: auto;
                width: 100vw !important;
                visibility: visible;
                max-width: unset !important;
                height: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .Lightbox {
        &__Content {
            &__image {
                max-width: 100%;
            }
        }
    }
}

.vjs-tech {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
}

.vjs-big-play-centered {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
}

.magnifier-image {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}
