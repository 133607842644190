@import "../../../styles/variables";

@mixin lozenge {
    background: $properBlack;
    color: $white;
    width: auto;
    margin: 0 auto;
    font-size: 12px;
    font-family: "CircularStd";
    font-weight: $weightMedium;
    padding: 5px 13px;
    border-radius: 50vw;
    text-align: center;
    transition: 0.5s ease;
}

.Card {
    $self: &;
    border: 0;
    font-size: 16px;
    font-weight: $weightBook;
    color: $white;
    margin-bottom: 10px;
    position: relative;
    width: 100%;

    &--blank {
        background: transparent;

        img {
            cursor: pointer;
        }
    }

    &--expand {
        transition: width 0.5s ease;
        width: 25%;

        &.active {
            width: 100%;
        }
    }

    &--fullwidth {
        max-width: 100%;
        width: 100%;
    }

    &--choice {
        padding-right: 15px;
    }

    &--required {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 2px solid black;

        &.Card--image::after {
            content: "Click the image to continue";
        }

        &.Card--video::after {
            content: "Watch the video to continue";
        }

        &.Card--choice::after {
            content: "Select an option to continue";
        }

        &:after {
            @include lozenge();
            white-space: nowrap;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            transition: 0.2s ease-in;
            opacity: 1;
        }

        &.activated {
            transition: 0.2s ease-in;
            &:after {
                height: 0px;
                opacity: 0;
            }
        }
    }

    &--summary {
        text-align: left;
    }

    &--button {
        float: right;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 12pt;
        cursor: pointer;

        &:hover {
            background-color: #f1f3f5;
        }
    }

    &--system {
        min-height: auto;
        max-width: 100%;

        display: flex;

        p {
            @include lozenge();
            position: relative;
        }

        &::before {
            border-bottom: 2px solid $properBlack;
            content: "";
            position: absolute;
            bottom: 50%;
            width: 100%;
        }

        &.white {
            &::before {
                border-bottom-color: $white;
            }

            p {
                background: #fff;
                color: $properBlack;

                border-radius: 6px;
            }
        }

        &.calendar {
            p {
                padding-left: 26px;
                position: relative;

                &::before {
                    content: url("../assets/cal.svg");
                    padding-right: 6px;
                    width: 12px;
                    height: 13px;
                    position: absolute;
                    top: 3px;
                    left: 7px;
                }
            }
        }

        &.percent {
            p {
                position: relative;
            }
        }
    }

    &__Wrapper {
        background: transparentize($properBlack, 0.2);
        border: 0;
        border-radius: 0 10px 10px 10px;
        box-shadow: $boxShadow;
        position: relative;
        margin-left: 15px;
        transition: left 0.5s ease, opacity 0.5s ease-out;
        opacity: 0;
        &--loaded {
            -webkit-animation: cardFadeIn 1s forwards;
            -moz-animation: cardFadeIn 1s forwards;
            -o-animation: cardFadeIn 1s forwards;
            -ms-animation: cardFadeIn 1s forwards;
            animation: cardFadeIn 1s forwards;
        }

        .play-button {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.1);
            .carot {
                background-color: #c4302b;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                position: absolute;
                top: 50%;
                left: 50%;
                box-shadow: $boxShadow;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                transition: 0.4s ease;
                &:before {
                    content: url("../assets/play.svg");
                    position: absolute;
                    top: 53%;
                    left: 53%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &__Avatar {
        height: 50px;
        width: 50px;

        position: absolute;
        top: 0;
        left: 0;

        border-radius: 0 0 10px 0;
    }

    &__Image,
    &__Video {
        border-radius: 10px;
        position: relative;
        width: 100%;
        min-height: 30px;
    }

    &__Video {
        text-align: center;

        video {
            max-width: 320px;
            margin: auto;

            // TODO: Revisit A11y after design pass
            &:focus {
                outline: none;
            }
        }

        .video-js {
            height: auto;
            &.vjs-fullscreen {
                z-index: 10;
            }
            video {
                position: static;
            }
        }
    }

    &__Expand {
        background-color: $white;
        background-image: url("../assets/expand.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 13px 13px;
        border-radius: 10px 0 10px 0;
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 30px;
        height: 30px;

        &__Multi {
            background-image: url("../assets/cards.svg");
        }
    }

    &--video {
        button {
            background: transparentize($properBlack, 0.5);
            border-radius: 0 0 10px 10px;
            position: relative;
            padding: 0;
            border: 0;
        }

        &.Card--noText {
            .Card__Content {
                padding-top: 38px;
            }
        }
    }
    &--image {
        button {
            padding: 0px;
            border: 0px;
            border-radius: 10px;
            margin: 0 auto;
            display: block;
            position: relative;
            box-shadow: $boxShadow;
            overflow: hidden;
        }
    }

    &--content-image {
        .Card__Wrapper {
            padding: 18px 12px;
        }
    }

    &__Content {
        padding: 18px 12px 20px 63px;
        line-height: 1.4;

        &--inline {
            max-width: 320px;
            width: 100%;
            cursor: pointer;
        }

        &--inline-container {
            padding: 18px 12px;
            max-width: 320px;
            position: relative;
            margin: auto;
        }

        &--inline-image-wrapper {
            border-radius: 10px;
            overflow: hidden;
            line-height: 0;
            .Card__Expander {
                border-bottom-right-radius: 10px;
                cursor: pointer;
            }
        }
    }

    &.activated {
        .Card__Content--inline {
            cursor: auto;
        }
    }

    &__Expander {
        display: block;
        content: "";
        background-color: $white;
        background-image: url("../assets/expand.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 13px 13px;
        position: absolute;
        bottom: -2px;
        right: 0;
        width: 30px;
        height: 30px;
        margin: 20px 12px;
        border-top-left-radius: 10px;
        outline: none;
    }

    &__ChapterEnd {
        background: $white;
        border: 0;
        border-radius: 10px;
        box-shadow: $boxShadow;
        padding: 15px;
        width: 100%;
        color: $black;
        cursor: pointer;
        .heading {
            font-size: 21px;
            display: block;
            margin: 0 auto 5px;
            font-weight: $weightBlack;
        }
    }

    &__Choice {
        $self: &;
        background: $white;
        border: none;
        border-radius: 10px;
        box-shadow: $boxShadow;
        color: $black;
        cursor: pointer;
        font-size: 15px;
        font-weight: $weightBlack;

        margin-bottom: 10px;
        min-height: 75px;
        text-align: left;
        opacity: 0;
        padding: 0 15px;
        width: 100%;
        transition: all 0.75s ease-in-out;
        will-change: transform;

        &--display {
            opacity: 1;
            transform: translate(0);
            &#{ $self }--leave {
                opacity: 0;
                height: 0px;
                min-height: 0px;
                box-shadow: none;
                transform: translate(150%);
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            transition: all 0.5s ease;
            opacity: 0;
            content: "";
            background-color: #fff;
            background-image: url(../assets/redo.svg);
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
            border-radius: 100%;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 50%;
            transform: translate(50%, -50%);
            width: 45px;
            height: 45px;
            right: 0;
        }

        &--chosen {
            &::before {
                opacity: 1;
            }

            &:hover {
                opacity: 1;

                &::before {
                    transform: translate(50%, -50%) rotate(-45deg);
                }
            }
        }

        &:hover {
            opacity: 0.6;
            @media (hover: none), (any-pointer: coarse) {
                opacity: 1;
            }
        }
    }

    .Spinner {
        right: 0;
        bottom: 0;
    }

    &__History--card-single {
        .Card__Wrapper {
            opacity: 1;
        }
    }
}

.videoFullscreen {
    .Card {
        visibility: hidden;
        &__Video {
            .video-js {
                position: fixed;
                top: -4vh;
                right: -100px;
                bottom: -4vh;
                z-index: 99999;
                left: auto;
                width: 100vw !important;
                visibility: visible;
                max-width: unset !important;
            }
        }
    }
}

.Summary {
    background-color: $white;
    border-radius: 10px;
    color: $charcoal;
    // cursor: pointer;
    display: flex;
    margin-bottom: 25px;
    animation: cardFadeIn 1s forwards;
    &__Image {
        position: relative;
        width: 25%;

        img {
            transform: rotate(-25deg);
            max-width: 210px;
            width: 100%;
        }
    }

    &__Text {
        flex: 1;
        padding: 15px 15px 15px 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &--title {
            margin-bottom: 10px;
            font-size: 21px;
        }

        &--instruction {
            @include lozenge();
            white-space: normal;
            height: auto;
            line-height: 15px;
            margin: auto 0 0;
            padding: 5px 10px;
            flex: 0 1 auto;
        }
    }

    button {
        margin-top: 10px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 800;
        transition: 0.5s ease;
        cursor: pointer;
        background-color: $properBlack;
        color: white;
        &:hover {
            background-color: $black;
        }
    }
}

@media only screen and (max-width: 767px) {
    .Summary {
        flex-direction: column;
        margin-top: 30%;
        &__Image {
            width: 50%;
            margin: -30% auto 10px;
            img {
                transform: rotate(-12deg);
                max-width: 300px;
            }
        }

        &__Text {
            padding: 15px 15px 30px;
            &--body {
                margin-bottom: 30px;
            }
        }
    }
}

@media only screen and (max-width: 469px) {
    .Summary {
        &__Image {
            width: 25%;
        }
    }
}

.vjs-control-bar {
    border-radius: 0px 0px 10px 10px;
}

@keyframes cardFadeIn {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
