@import '../../../styles/variables';

.Debug {
    background: $white;
    border: 2px solid $black;
    box-shadow: -1px -1px 34px 0px rgba(0, 0, 0, 0.75);
    font-size: 0.8em;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 200px;
    max-height: 150px;
    z-index: 999;
    &--button {
        cursor: pointer;
    }

    &--top {
        bottom: auto;
        top: 0;
    }

    &--ontop {
        z-index: 999;
    }
}

.videoFullscreen {
    .Debug {
        display: none;
    }
}

@media only screen and (max-width: 469px) {
    .Debug {
        display: none;
    }
}
